import { React, createContext, useContext, useState } from 'react'

const defaultState = {
  formOpen: false,
  formType: 'QUOTE',
  setFormOpen: () => {},
  setFormType: () => {},
}

// const types = ['MESSAGE', 'QUOTE', 'COLLAB', 'CARRIER']

const FormContext = createContext(defaultState)

export const FormProvider = ({ children }) => {
  const [formOpen, setOpen] = useState(defaultState.formOpen)
  const [formType, setType] = useState(defaultState.formType)

  const setFormOpen = (openBool) => {
    setOpen(openBool)
  }

  const setFormType = (newType) => {
    setType(newType)
  }

  return (
    <FormContext.Provider
      value={{
        formOpen,
        formType,
        setFormOpen,
        setFormType,
      }}>
      {children}
    </FormContext.Provider>
  )
}

export default FormContext

export const useFormContext = () => useContext(FormContext)
